const { default: RootWrapper } = require("components/page/RootWrapper");
const { setCookie, getCookie } = require("components/page/util/cookie");

const COOKIE_NAME = "session_start_page";
const COOKIE_EXPIRY_DAYS = 1;

exports.wrapRootElement = RootWrapper;

exports.onRouteUpdate = ({ location }) => {
  const firstPageVisit = getCookie(COOKIE_NAME);
  if (!firstPageVisit) {
    setCookie(COOKIE_NAME, location.href, COOKIE_EXPIRY_DAYS);
    console.log(`First page visit tracked: ${location.href}`);
  }
};
